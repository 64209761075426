import Swal from 'sweetalert2';

export function handleErrors(response) {
	let errorDetails = '';

	if (response?.data?.message && response?.data?.errors) {
		const { message, errors } = response.data;
		errorDetails = message;

		let itemError = [];
		Object.keys(errors).forEach((key) => {
		errors[key].forEach((description) => {
			itemError.push(`<li>${description}</li>`);
		});
		});

		errorDetails += '<ul>' + itemError.join('') + '</ul>';
	} else if (response?.data?.msg) {
		errorDetails = response.data.msg;
	} else {
		errorDetails = 'No se obtuvo una respuesta del servidor';
	}

	Swal.fire({
		title: errorDetails ? 'Validación' : 'Error',
		html: errorDetails || 'Ocurrió un error al procesar.',
		icon: errorDetails ? 'warning' : 'error',
	});
}
