import React, { useState } from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';

const DatosCardConTabs = ({ datos }) => {
	const [activeTab, setActiveTab] = useState('empresa');

	return (
		<Card className="shadow card-custom-bg w-100" style={{ maxWidth: '500px', marginBottom: '20px' }}>
			<Card.Body>
				<Tabs
					id="datos-tabs"
					activeKey={activeTab}
					onSelect={(k) => setActiveTab(k)}
					className="mb-3"
				>
					<Tab eventKey="empresa" title="Empresa">
						<ul className="list-group list-group-flush">
							<li className="list-group-item"><strong>Empresa: </strong>{datos.empresa}</li>
							<li className="list-group-item"><strong>Manifiesto: </strong>{datos.manifiesto}</li>
						</ul>
					</Tab>
					<Tab eventKey="conductor" title="Conductor">
						<ul className="list-group list-group-flush">
							<li className="list-group-item"><strong>Documento: </strong>{datos.conductor}</li>
							<li className="list-group-item"><strong>Nombre Completo: </strong>{datos.nombreConductor}</li>
						</ul>
					</Tab>
					<Tab eventKey="ruta" title="Ruta">
						<ul className="list-group list-group-flush">
							<li className="list-group-item"><strong>Vehículo: </strong>{datos.vehiculo}</li>
							<li className="list-group-item"><strong>Ruta: </strong>{datos.ruta}</li>
						</ul>
					</Tab>
				</Tabs>
			</Card.Body>
		</Card>
	);
};

export default DatosCardConTabs;
