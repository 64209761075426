import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Spinner, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from '../../axiosConfig';
import '../../css/Novedades.css';
import BackButton from '../../components/globales/BackButton';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleErrors } from '../../components/helpers/handleErrors';


export const Novedades = () => {
	const [location, setLocation] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');

	const [file, setFile] = useState(null); // Estado para manejar el archivo de imagen

	const navigate = useNavigate();

	useEffect(() => {
		const tokenConductor = localStorage.getItem('tokenConductor');
		if (!tokenConductor) {
			Swal.fire({
				icon: 'warning',
				title: 'Acceso restringido',
				text: 'Debe validar sus datos para acceder a esta sección.',
			}).then(() => {
				navigate('/onroad');
			});
		}
	}, [navigate]);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setLocation(`Lat: ${latitude}, Lng: ${longitude}`); 
					setLatitude(latitude);
					setLongitude(longitude);
				},
				() => {
					Swal.fire({
						title: 'Error al obtener ubicación',
						text: 'Por favor, habilite la ubicación para continuar.',
						icon: 'error',
						confirmButtonText: 'Cerrar'
					}).then((result) => {
						if (result.isConfirmed) {
							navigate('/principalonroad');
						}
					});
				}
			);
		} else {
			Swal.fire({
				title: 'Error',
				text: 'La ubicación no está soportada por este navegador.',
				icon: 'error',
				confirmButtonText: 'Cerrar'
			}).then((result) => {
				if (result.isConfirmed) {
					navigate('/principalonroad');
				}
			});
		}
	}, []);

	const { state } = useLocation();
	const title = state?.title || "Operación";
	const label = state?.label || "Novedad";
	const labelReadOnly = state?.readOnly || false;

	const [novedad, setNovedad] = useState(state?.tipoMovimiento || "");
	const [observaciones, setObservaciones] = useState('');
	const [loading, setLoading] = useState(false);

	const handleLocation = (e) => {
		if (navigator.geolocation) {
		  navigator.geolocation.getCurrentPosition(
			(position) => {
			  const { latitude, longitude } = position.coords;
			  setLocation(`Lat: ${latitude}, Lng: ${longitude}`); 
			  setLatitude(latitude);
			  setLongitude(longitude);
	  
			  // Mostrar notificación de éxito usando Toastify
			  toast.success('Ubicación actualizada correctamente!', {
				position: "top-right", // Posición en la pantalla
				autoClose: 3000, // Duración en ms
			  });
			},
			() => {
			  // Mostrar notificación de error usando Toastify
			  toast.error('Error al obtener la ubicación. Por favor, habilite la ubicación.', {
				position: "top-right",
				autoClose: 3000,
			  });
			}
		  );
		} else {
		  toast.error('La ubicación no está soportada por este navegador.', {
			position: "bottom-right",
			autoClose: 3000,
		  });
		}
	  };
	  

	const handleFileChange = (e) => {
		setFile(e.target.files[0]); // Guarda el archivo seleccionado
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!novedad || !observaciones) {
			Swal.fire({
				title: 'Error',
				text: 'Por favor, complete todos los campos antes de enviar.',
				icon: 'error',
			});
			return;
		}

		const formData = new FormData();
		formData.append('manifiesto', localStorage.getItem('manifiesto'));
		formData.append('puestoControl', novedad);
		formData.append('observaciones', observaciones);
		formData.append('latitud', latitude);
		formData.append('longitud', longitude);
		formData.append('tokenConductor', localStorage.getItem('tokenConductor'));

		if (file) {
			formData.append('novedadImagen', file); // Agregar archivo al FormData
		}

		setLoading(true);
		
		sendData(formData);
	};

	const sendData = async (formData) => {
		try {
			const response = await axios.post('/enviarPuestoControl', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			setLoading(false);

			if (response.data.success) {
				Swal.fire({
					title: 'Novedad Enviada',
					text: response.data.msg || 'La novedad se ha enviado exitosamente.',
					icon: 'success',
				});

				setNovedad('');
				setObservaciones('');
				setFile('');
			} else {
				handleErrors(response);
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
			Swal.fire({
				title: 'Error',
				text: 'Hubo un problema al enviar la novedad.',
				icon: 'error',
			});
		}
	};

	return (
		<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
			<Card className="shadow " style={{ width: '100%', maxWidth: '500px', padding: '20px' }}>
				<BackButton onClick={() => navigate(-1)} />
				<Row className="w-100 justify-content-center mb-4">
					<Col xs={12} md={8} lg={6} className="d-flex justify-content-center">
						<img 
						src="/logosafite2.png" 
						alt="Logo" 
						className="logo logo-header img-fluid" 
						style={{ maxWidth: '100%' }} 
						/>
					</Col>
				</Row>

				<h2 className="text-center mb-4">{title}</h2>
				<Form className="mb-5" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="formTitle">
						<Form.Label>{label}</Form.Label>
						<Form.Control
							type="text"
							placeholder="Ingrese Novedad"
							value={novedad}
							onChange={(e) => setNovedad(e.target.value)}
							required
							readOnly={labelReadOnly}
							minLength={3}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formDescription">
						<Form.Label> Observaciones</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							placeholder="Observación de la novedad"
							value={observaciones}
							minLength={3}
							onChange={(e) => setObservaciones(e.target.value)}
							required
							style = {{
								resize: "none"
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formImageUpload">
						<Form.Label>
							<span className="material-icons" style={{ verticalAlign: 'middle' }}>image</span> Subir imagen
						</Form.Label>
						<Form.Control 
							type="file"
							accept="image/*"
							value={file ? undefined : ''}
							onChange={handleFileChange}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formTitle">
						<Row>
							<Form.Label>Localización Actual</Form.Label>
						</Row>
						<Row>
							<Col xs={9}>
								<Form.Control
									type="text"
									placeholder="Otorgar permisos de ubicación"
									value={location}
									readOnly 
								/>
							</Col>
							<Col xs={3}>
								<Button variant="success" onClick={handleLocation}><span className="material-icons">location_on</span></Button>
							</Col>
						</Row>
					</Form.Group>
					<Button variant="primary" type="submit" className="w-100" disabled={loading}>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							<>
								<span className="material-icons" style={{ verticalAlign: 'middle' }}>add_location</span> Reportar
							</>
						)}
					</Button>

					<Row className="w-100 mt-5 mb-5 justify-content-center mb-4">
						<Col xs={12} className="d-flex justify-content-center">
							<div className="footer-copyright">
								&copy; 2024 Safite. Todos los derechos reservados.
							</div>
						</Col>
					</Row>
				</Form>
				<ToastContainer />
			</Card>
		</Container>
	);
};
