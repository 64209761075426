import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SalirOnRoad = () => {
  const navigate = useNavigate();

  const handleClearAndNavigate = () => {
    localStorage.clear();
    navigate('/onroad');
  };

  return (
    <button onClick={handleClearAndNavigate} className="btn btn-danger">
        <span className="material-icons" style={{ verticalAlign: 'middle' }}>power_settings_new</span><strong> Salir</strong>
    </button>
  );
};
