import axios from 'axios';

const instance = axios.create({
	//baseURL: 'https://preoperacional.safite.com/api',
	baseURL: 'https://onroad.dev.safite.com/api',
	//baseURL: 'http://localhost:8000/api',
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401) {
			localStorage.removeItem('token');
		}
		return Promise.reject(error);
	}
);

export default instance;
