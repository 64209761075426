import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';

import axios from '../../axiosConfig';

const ValidarConductor = () => {
	const [numeroIdentificacion, setNumeroIdentificacion] = useState('');
	const [numeroManifiesto, setManifiesto] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate(); 

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError('');
		localStorage.clear();

		try {
			const response = await axios.get('/validarConductor', {
				params: {
					identificacion: numeroIdentificacion,
					manifiesto: numeroManifiesto,
				},
				validateStatus: (status) => {
					return status >= 200 && status < 500; // Considera códigos de 2xx a 4xx como exitosos
				}
			});

			if (response.data && response.data.tokenOperacion) {
				localStorage.setItem('tokenConductor', response.data.tokenOperacion);
				localStorage.setItem('id_empresa', response.data.operacion.id_empresa_codigo);
				localStorage.setItem('empresa', response.data.operacion.nombre_empresa);
				localStorage.setItem('manifiesto', response.data.operacion.manifiesto);
				localStorage.setItem('conductor', response.data.operacion.conductor);
				localStorage.setItem('nombreConductor', response.data.operacion.nombre_conductor);
				localStorage.setItem('placa', response.data.operacion.placa);
				localStorage.setItem('ruta', response.data.operacion.ruta_viaje);

				navigate('/principalonroad');
			} else {

				const { message, errors } = response.data;

				let errorDetails = message;
				if (errors) {
					Object.keys(errors).forEach((key) => {
						errorDetails += `\n${key}: ${errors[key].join(', ')}`;
					});
				}

				throw new Error(errorDetails || 'Error desconocido');
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
			<Card className="shadow" style={{ width: '100%', maxWidth: '500px', padding: '20px' }}>
				<Row className="w-100 justify-content-center mb-4">
					<Col xs={12} className="d-flex justify-content-center">
						<img src="/logosafite2.png" alt="Logo" width={200} className="logo-animated" />
					</Col>
				</Row>
				<h2 className="text-center mb-4">Ingresar OnRoad</h2>

				<Form onSubmit={handleSubmit}>
				{/* Campo para el título */}
				<Form.Group className="mb-3" controlId="formTitle">
					<Form.Label>Nro. Identificación</Form.Label>
					<Form.Control
					type="text"
					placeholder="Ingrese número de identificación"
					value={numeroIdentificacion}
					onChange={(e) => setNumeroIdentificacion(e.target.value)}
					required
					/>
				</Form.Group>

				<Form.Group className="mb-3" controlId="formTitle">
					<Form.Label>Nro. Manifiesto</Form.Label>
					<Form.Control
					type="text"
					placeholder="Ingrese número de manifiesto"
					value={numeroManifiesto}
					onChange={(e) => setManifiesto(e.target.value)}
					required
					/>
				</Form.Group>


				{/* Mensaje de error */}
				{error && <p className="text-danger">{error}</p>}

				{/* Botón de enviar */}
				<Button variant="primary" type="submit" className="w-100" disabled={loading}>
					{loading ? 'Validando...' : (
					<>
						<span className="material-icons" style={{ verticalAlign: 'middle' }}>login</span> Ingresar
					</>
					)}
				</Button>
				</Form>
			</Card>
		</Container>
	);
};

export default ValidarConductor;
