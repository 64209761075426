import React from 'react';
import { Button } from 'react-bootstrap';

const BackButton = ({ onClick, text = 'Regresar' }) => {
  return (
    <div className="text-start mb-3 button-fade-in">
      <Button variant="link" onClick={onClick} className="p-0 text-primary">
        <span className="material-icons" style={{ verticalAlign: 'middle' }}>arrow_back</span>
        {text}
      </Button>
    </div>
  );
};

export default BackButton;