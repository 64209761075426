import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import axios from '../../axiosConfig';
import DatosCardConTabs from '../../components/onroad/TabCard';
import { SalirOnRoad } from '../../components/onroad/SalirOnRoad';
import Swal from 'sweetalert2';
import '../../css/TorreControl.css';

export const PrincipalOnRoad = () => {    
	const navigate = useNavigate();

	useEffect(() => {
		const tokenConductor = localStorage.getItem('tokenConductor');
		if (!tokenConductor) {
			Swal.fire({
				icon: 'warning',
				title: 'Acceso restringido',
				text: 'Debe validar sus datos para acceder a esta sección.',
			}).then(() => {
				navigate('/onroad');
			});
		}
	}, [navigate]);

	const [loading, setLoading] = useState(true);
	const [datos, setDatos] = useState({
		manifiesto: '',
		empresa: '',
		conductor: '',
		nombreConductor: '',
		ruta: '',
		ciudadOrigen: '',
		ciudadDestino: '',
		vehiculo: '',
		tokenConductor: '',
	});

	const obtenerDatosDeLocalStorage = () => {
		return {
			manifiesto: localStorage.getItem('manifiesto') || '',
			empresa: localStorage.getItem('empresa') || '',
			conductor: localStorage.getItem('conductor') || '',
			nombreConductor: localStorage.getItem('nombreConductor') || '',
			ruta: localStorage.getItem('ruta') || '',
			ciudadOrigen: localStorage.getItem('ruta') || '',
			ciudadDestino: localStorage.getItem('ruta') || '',
			vehiculo: localStorage.getItem('placa') || '',
			tokenConductor: localStorage.getItem('tokenConductor') || '',
		};
	};

	useEffect(() => {
		const datosCargados = obtenerDatosDeLocalStorage();
		setDatos(datosCargados);
		setLoading(false);
	}, []);

	const handlePreOperacionalClick = async () => {
		try {
			const response = await axios.get('/obtenerPreOperacional', {
				params: {
					manifiesto: datos.manifiesto,
					referencia : datos.tokenConductor
				},
			});
	
			const { success, message, data } = response.data;
	
			if (success) {
				const {url} = data;
				
				if (url) {
					try {
						// Validar URL
						const validatedUrl = new URL(url, window.location.origin).href;
						window.open(validatedUrl, '_blank');
					} catch (validationError) {
						Swal.fire({
							icon: 'error',
							title: 'Error en la URL',
							text: `La URL recibida no es válida: ${url}`,
						});
					}
				} else {
					Swal.fire({
						icon: 'warning',
						title: 'Advertencia',
						text: 'No se recibió la URL esperada.',
					});
				}
			} else {
				Swal.fire({
					icon: message ? 'warning' : 'error',
					title: message ? 'Advertencia' : 'Error',
					text: message || 'Ocurrió un error al procesar la solicitud.',
				});
			}
		} catch (error) {
			console.error('Error al obtener los datos:', error);
			Swal.fire({
				icon: 'error',
				title: 'Error inesperado',
				text: 'Ocurrió un error al comunicarse con el servidor. Por favor, inténtalo más tarde.',
			});
		}
	};

	const cardsData = [
		{
			title: 'Pre - Operacional',
			icon: 'check_circle_outline',
			description: 'Encuesta para conocer el estado técnico e higiénico del vehículo.',
			imageUrl: 'images/operacional-ico.webp',
			onClick: handlePreOperacionalClick,
		},
		{
			title: 'Reportar Salida',
			icon: 'local_shipping',
			description: 'Reportar la salida del origen.',
			imageUrl: 'images/salida-ico.png',
			linkTo: '/novedades',
			state: { title: 'Reportar Salida', label: 'Novedad Salida', tipoMovimiento: `SALIDA DE SITIO ${datos.ciudadOrigen}`, readOnly: true },
		},
		{
			title: 'Reportar Novedad',
			icon: 'warning',
			description: 'Reporta novedades en el camino (retenes, paros, derrumbes, entre otros...).',
			imageUrl: 'images/novedades-ico.png',
			linkTo: '/novedades',
			state: { title: 'Novedades en Ruta',  label: 'Novedad', tipoMovimiento: '' },
		},
		{
			title: 'Reportar Llegada',
			icon: 'forklift',
			description: 'Reportar la llegada al destino.',
			imageUrl: 'images/llegada-ico.png',
			linkTo: '/novedades',
			state: { title: 'Reportar Llegada', label: 'Novedad Llegada',tipoMovimiento: `LLEGADA A SITIO ${datos.ciudadDestino}`, readOnly: true },
		},
	];

	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Cargando...</span>
				</Spinner>
			</div>
		);
	}

	return (
		<Container 
			fluid
			className="d-flex flex-column align-items-center px-3"
			style={{
				minHeight: '100vh',
				paddingTop: '60px',
				paddingBottom: '60px',
				overflowX: 'hidden',
				backgroundColor: '#f5f5f5',
			}}
		>
			{/* Logo */}
			<Row className="w-100 justify-content-center mb-4">
				<Col xs={12} className="d-flex justify-content-center">
					<img src="/logosafite2.png" alt="Logo" width={200} className="logo-animated" />
				</Col>
			</Row>

			{/* Card de Datos */}
			<DatosCardConTabs datos={datos} />

			<Row className="w-100 justify-content-center">
				{cardsData.map((card, index) => (
					<Col
						key={index}
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="mb-4 d-flex justify-content-center"
					>
						{card.linkTo ? (
							<Link
								to={card.linkTo}
								state={card.state}
								className="no-link-style w-100"
								style={{ textDecoration: 'none' }}
							>
								<Card
									className="shadow card-animated"
									style={{
										cursor: 'pointer',
									}}
								>
									<Card.Body className="d-flex align-items-center">
										<img
											src={card.imageUrl}
											alt={card.title}
											className="me-3"
											style={{
												width: '90px',
												height: '70px',
												objectFit: 'contain',
											}}
										/>
										<div>
											<Card.Title className="text-dark mb-1">
												{card.title}
											</Card.Title>
											<Card.Text className="text-muted">{card.description}</Card.Text>
										</div>
									</Card.Body>
								</Card>
							</Link>
						) : (
							<Card
								className="shadow card-animated"
								style={{
									cursor: 'pointer',
								}}
								onClick={card.onClick}
							>
								<Card.Body className="d-flex align-items-center">
									<img
										src={card.imageUrl}
										alt={card.title}
										className="me-3"
										style={{
											width: '90px',
											height: '70px',
											objectFit: 'contain',
										}}
									/>
									<div>
										<Card.Title className="text-dark mb-1">
											{card.title}
										</Card.Title>
										<Card.Text className="text-muted">{card.description}</Card.Text>
									</div>
								</Card.Body>
							</Card>
						)}
					</Col>
				))}
			</Row>


			<Row className="w-100 justify-content-center mb-4">
				<Col xs={12} className="d-flex justify-content-center">
					<SalirOnRoad />
				</Col>
			</Row>

			<Row className="w-100 mt-5 justify-content-center mb-4">
				<Col xs={12} className="d-flex justify-content-center">
					<div className="footer-copyright">
						&copy; 2024 Safite. Todos los derechos reservados.
					</div>
				</Col>
			</Row>
		</Container>
	);
};
